@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Inter','Syne', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #161140;
  border-radius: 10px;
}

::-moz-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #161140;
}

::-moz-scrollbar-thumb {
  background-color: #161140;
  border-radius: 10px;
}

::-ms-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-ms-scrollbar-thumb {
  background-color: #161140;
  border-radius: 10px;
}

::scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::scrollbar-thumb {
  background-color: #161140;
  border-radius: 10px;
}

.custom-text {
  color: #ed1c24;
  background-color: yellow;
}

.main-body {
  background: #fafafa;
  min-height: 100vh;
  border: 1px solid #fafafa;
}
.inputStyle {
  width: 4rem;
  height: 4rem;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  border: 1px solid #a0aec0;
  outline: none;
  text-align: center;
  padding: 0.25rem;
  border-radius: 5px;
}

.search-box {
  border: none !important;
  outline: none !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 5px !important;
}

.category-name {
  border-radius: 0px 0px 10px 10px;
  text-align: center;
  color: white;
  padding: 5px;
  width: 100%;
}

.md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
  margin: 0 !important;
}

.error-message{
  margin-top: 5px !important;
}

.place-select .css-13cymwt-control,
.place-select .css-t3ipsp-control{
  border-radius: 10px;
  background: rgb(245 245 245/1) !important;
  margin-bottom: 10px;
}

.place-select input{
  width: 100% !important;
  border: none !important;
  min-width: 100px !important;
  outline: none !important;
  box-shadow: none !important;
}

@media screen and (max-width: 480px) {
  .react-multiple-carousel__arrow--left {
    left: 0px !important;
    }
    
    .react-multiple-carousel__arrow--right {
      right: 0px !important;
      }
}
